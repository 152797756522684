import request  from "@/config/request";
//登录
  export const login = (data)=> {
    return request({
      url:'/api/member/wechatlogin',
      method:'post',
      data:data
    })
  }
   //分享
   export const jsapiSignature = (data)=> {
    return request({
      url:'/api/member/index',
      method:'post',
      data:data
    })
  }
  export const detail = (data)=> {
    return request({
      url:'/api/member/detail',
      method:'post',
      data:data
    })
  }
  export const getOptions = (data)=> {
    return request({
      url:'/api/member/getOptions',
      method:'post',
      data:data
    })
  }
  export const uploadImage = (data)=> {
    return request({
      url:'/api/common/uploads',
      method:'post',
      data:data
    })
  }
  export const save = (data)=> {
    return request({
      url:'/api/member/save',
      method:'post',
      data:data
    })
  }
  export const userList = (params)=> {
    return request({
      url:'/api/member/lists',
      method:'get',
      params
    })
  }
  export const getisAdmin = (data)=> {
    return request({
      url:'/api/member/getisAdmin',
      method:'post',
      data:data
    })
  }