import {
  createRouter,
  createWebHistory,
  createWebHashHistory 
} from 'vue-router'
const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'home',
    meta: {
      showRule: false
    },
    component: () => import('../views/Home.vue')
  },
  // {
  //   path: '/admin',
  //   name: 'admin',
  //   meta: {
  //     showRule: false
  //   },
  //   component: () => import('../views/admin.vue')
  // },
  // {
  //   path: '/userinfo',
  //   name: 'userinfo',
  //   meta: {
  //     showRule: false
  //   },
  //   component: () => import('../views/userinfo.vue')
  // },
]
const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
})

export default router