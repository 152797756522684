import {jsapiSignature} from "@/api";
import wxShareApi from "./wxShareApi";
const wxShare = {
  setShareInfo(){
    let urls="";
    let userAgent = navigator.userAgent;
    if (userAgent.includes("iPhone") || userAgent.includes("iPad")) {
      urls=sessionStorage.getItem("originUrl");
    }else{
      urls=location.href;
    }
    console.log("======",urls)
    jsapiSignature({appId:'wx80d18ecedcf92e4f',url:urls}).then(res=>{
      console.log(res)
      if(res.code==0){
        wxShareApi.wxRegister(res.data)
      }
    })
  }
}
export default wxShare
