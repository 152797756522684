import axios from 'axios'
import verifyWxLogin from "@utils/verifyWxLogin.js"
const service = axios.create({
    baseURL: process.env.NODE_ENV=='development'?'':process.env.VUE_APP_BASE_API, // api的base_url
    timeout: 30000 // 请求超时时间
})
service.interceptors.request.use(
	config => {
		if(localStorage.getItem("openId")){
			config.headers['Authorization'] =localStorage.getItem("openId");
		}
		if(config.url.indexOf("$")!=-1){//判断是否为获取本地文件，如果是则修改默认获取路径
			config.baseURL = window.location.origin;
			config.url =  config.url.split("$")[1];
		}
		console.log(config)
		return config;
	},
	err => {
		return Promise.reject(err);
	});

// http response 拦截器
service.interceptors.response.use(
	response => {
		response.data = typeof(response.data)==="string"?JSON.parse(response.data):response.data;
		if(response.config.url.indexOf(".js")!=-1){ //判断是否获取的是js文件
			return response.data;
		}else if(response.data.code!== 0){
		// 	return Promise.reject("服务器错误，请稍后重试！")
		}
		if(response.data.code==-401){ //重新登录
			verifyWxLogin.getCode()
		  }
		return response.data;
	},
	error => {
		return Promise.reject(error);
	}
);
export default service
