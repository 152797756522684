import wx from 'weixin-js-sdk'
const util = {
  /**
   * 判断是ios还是苹果
   */
  appSource() {
    const u = navigator.userAgent,
      app = navigator.appVersion;
    //const isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1; //g
    const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
    if (isiOS) {
      return "ios";
    } else {
      return "andriod";
    }
  },
  /**
   * 获取url中的参数
   * @param {*} name
   * @param {*} url
   */
  getUrlKey(name, url) {
    return (
      decodeURIComponent(
        (new RegExp("[?|&]" + name + "=" + "([^&;]+?)(&|#|;|$)").exec(
          url
        ) || [, ""])[1].replace(/\+/g, "%20")
      ) || null
    );
  },
   //获取url中的参数
   getUrlParam(name){
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)"); //构造一个含有目标参数的正则表达式对象
    var r = window.location.search.substr(1).match(reg); //匹配目标参数
    if (r != null) return unescape(r[2]); return null; //返回参数值
  },
  strFormat(x){
    return x == undefined ? "" : x == "undefined" ? "" : x == null ? "" : x == "null" ? "" : x;
  },
  offsetTop(a) {
    for (var b; void 0 === a.offsetTop;) a = a.parentNode;
    for (b = a.offsetTop; a = a.offsetParent;) b += a.offsetTop;
    return b
  },
  getElementToPageTop(el) {
    if (el.parentElement) {
      return this.getElementToPageTop(el.parentElement) + el.offsetTop
    }
    return el.offsetTop
  },
  // min < r ≤ max
  RandomNum(Min, Max) {
    var Range = Max - Min;
    var Rand = Math.random();
    if (Math.round(Rand * Range) == 0) {
      return Min + 1;
    }
    var num = Min + Math.round(Rand * Range);
    return num;
  },
   /* url地址修改
  * url 待修改url
  * arg 修改的参数名
  * arg_val 修改的具体值
  */
   changeURLArg(url,arg,arg_val){
    let pattern = arg + '=([^&]*)';
    let replaceText = arg + '=' + arg_val;
    if (url.match(pattern)) {
        let tmp = '/(' + arg + '=)([^&]*)/gi';
        tmp = url.replace(eval(tmp), replaceText);
        return tmp;
    } else {
        if (url.match('[\?]')) {
            return url + '&' + replaceText;
        } else {
            return url + '?' + replaceText;
        }
    }
  },
  getParams(name) { ///获取URL中的参数
    var r = String(window.location),
      _n = r.indexOf("?") + 1,
      _RP = '';
    var getpraJSON = function () {
      r = r.substring(_n);
      var narray = r.split("&"),
        _rP = {};
      for (var i = 0; i < narray.length; i++) {
        _rP[narray[i].split("=")[0]] = narray[i].split("=")[1] || '';
      }
      return _rP;
    }
    if (_n > 0) {
      if (name === 0) {
        _RP = r.substring(_n); //返回整个参数
      } else if (name) {
        _RP = getpraJSON()[name] || '';
        _RP = _RP ? _RP : ''; //返回指定字符串
      } else {
        _RP = getpraJSON(); //返回参数集合，json格式
      }
    }
    return _RP;
  },
  trim(s) {
    return s.replace(/(^\s*)|(\s*$)/g, "");
  },
  getNowDate(){
    const timeOne = new Date()
    const year = timeOne.getFullYear()
    let month = timeOne.getMonth()+1
    let day = timeOne.getDate()
    month = month < 10 ? '0' + month : month
    day = day < 10 ? '0' + day : day
    const NOW_MONTHS_AGO = `${year}-${month}-${day}`
    return NOW_MONTHS_AGO
  },
  miniProgramSource(){
    var ua = window.navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        wx.miniProgram.getEnv(function (res) {
          if (res.miniprogram) {
            console.log(1)
            return true
          } else {
            console.log(2)
            return false
          }
        });
      }
  },
  copyObj(target, source) {
    for (let key in target) {
      if (source.hasOwnProperty(key)) {
        target[key] = source[key];
      }
    }
  },
  reEncode(value){
    return value.replace(/[()]/g, function(match) {
        return '%' + match[0].charCodeAt(0).toString(16);
      });
  },
 removeEmptyValues(obj) {
    return Object.keys(obj).filter(key => {
      const value = obj[key];
      return (
        value !== null &&
        value !== undefined &&
        value !== '' &&
        (Array.isArray(value) ? value.length : true)
      );
    }).reduce((acc, key) => {
      acc[key] = obj[key];
      return acc;
    }, {});
  },
  sortObjectByKeys(obj) {
    const sortedKeys = Object.keys(this.removeEmptyValues(obj)).sort();
    const sortedObj = {};
    sortedKeys.forEach(key => {
      sortedObj[key] = typeof obj[key] === 'string'?this.trim(obj[key]): typeof obj[key] === 'object'?JSON.stringify(obj[key]):obj[key];
    });
    return sortedObj;
  },
  renderSign(obj){
   let newObj=this.sortObjectByKeys(obj)
   let arr=Object.entries(newObj)
   let str = arr.map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`).join('&');
   return  this.reEncode(str)
  }
}

export default util
