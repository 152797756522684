import {
  createStore
} from 'vuex'
export default createStore({
  state: {
    role:sessionStorage.getItem("role")||''
  },
  mutations: {
    setRole(state, payload) {
      state.role = payload;
    },
  },
  actions: {

  },
  modules: {}
})