import util from "@utils/util.js"
import {login,getisAdmin} from "@/api/index.js";
import store from '@/store'
const verifyWxLogin = {
  code: '',
  getCode(){
    this.code = util.getParams("code")
    if(!this.code){
      let loc_href = window.location.href; 
		  loc_href = encodeURIComponent(loc_href);
		  let appId = 'wx80d18ecedcf92e4f';
		  let wxUrl = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appId}&redirect\_uri=${loc_href}&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect`;

      window.location.replace(wxUrl)
    }else{
      console.log(this.code)
      this.getOpenId(this.code)
    }

  },
  getOpenId(code){
    login({code:code}).then((res)=>{
      if(res.code==1){
        localStorage.setItem("openId", res.data.token);
        console.log("token",res.data.token,store)
        getisAdmin().then(res=>{
          if(res.code==1){
            store.commit("setRole",res.data.isAdmin)
            sessionStorage.setItem("role",res.data.isAdmin);
          }
        })
        
        // this.redirectByRole(res.data.isAdmin)
      }
    })
  },
  redirectByRole(role) {
    if (role == '1') {
      this.$router.push({ path: '/admin' }); // 跳转到管理员页面
    } else if (role == '2') {
      this.$router.push({ path: '/userinfo' }); // 跳转到普通用户页面
    } 
  }
}

export default verifyWxLogin
